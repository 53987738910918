<template>
    <ORowContainer class="flex-row w-100 overflow-auto"> 
        <OColContainer class="flex-column user-select-none">
            <span class="mb-2">
                <input class="form-check-input" type="checkbox" id="restrictcontext" v-model="vRestrictToContext" @change="triggerRestrictToContext();">
                <label class="form-check-label ms-1" for="restrictcontext">{{$t('Restrict To User Context')}}</label>
            </span>
            <FieldFilter :filterObject="props.dataObject.filterObject" columnName="SearchColumn" placeHolder="Search..."></FieldFilter>
            <div v-for="folder in props.dataObject.data" :key="folder.index" >
                <span v-if="folder.isLoading" :style="[{'padding-left': (folder.level)*30+'px!important'}]">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </span>
                <div v-else class="p-2 text-truncate shared-folder-item"
                    :style="[{'padding-left': (folder.level)*30+'px!important'}]" 
                    >
                    <i :class="{'p-2 far bi bi-plus-square':!folder.expanded && folder.details.length > 0,'p-2 far bi bi-dash-square':folder.expanded && folder.details.length > 0,'p-2 far bi bi-dash-lg':folder.details.length == 0}" @click="triggerFolder(folder.index,folder)"></i>
                    <span class="shared-folder-name" role="button" @click="props.onFolderSelected(folder)">
                        <i class="fas fa-folder text-primary" v-if="folder.level == 0"></i>
                        <i class="fas fa-folder" v-else> </i>
                        <span class="p-2"> {{folder.Name}}</span>
                    </span>
                </div>
            </div>
        </OColContainer>
    </ORowContainer>
</template>

<script setup>
    import { ref } from 'vue';
    import { context } from 'o365-modules';
    import { $t as translate} from 'o365-utils';
    import { OFieldFilter as FieldFilter } from  'o365-filter-components'
    import 'o365-nodedata';

    const props = defineProps({
        dataObject: Object,
        onFolderSelected: Function,
        fieldForTreeify: { type: String, required: false, default:'IdPath' },
    })
    const vRestrictToContext = ref(false);
    let vWhereClause = `OrgUnitAccessIdPath LIKE '`+ context.idPath + `%'`;

    loadFoldersTree();

    context.onChanged(() => {
        vWhereClause = `OrgUnitAccessIdPath LIKE '`+ context.idPath + `%'`;
        loadFoldersTree();
    });

    function resetTree(expandToID){
        props.dataObject.load().then(() => {
            const node =  props.dataObject.nodeData.configurations[0].findNodeById(props.dataObject.storage.items.find(row => row.ID == expandToID))
            node?.expandTo();
        })
    }

    async function loadFoldersTree(){
        props.dataObject.recordSource.whereClause = vRestrictToContext.value == true ? vWhereClause : "";
        props.dataObject.nodeData.addConfiguration({
            idPathField: props.fieldForTreeify,
            type: 'hierarchy'
        })
        props.dataObject.nodeData.enable();
        props.dataObject.load();
    }

    function triggerRestrictToContext(){
        props.dataObject.recordSource.whereClause = vRestrictToContext.value == true ? vWhereClause : "";
        props.dataObject.load();
    }

    function triggerFolder(index,folder){
        if(folder.expanded){
            folder.collapse();
        }else{
            folder.expand();
        }
    }

    defineExpose({resetTree});
</script>

<style scoped>
.shared-folder-item:hover {
    background-color: rgba(var(--bs-primary-rgb), .5);
}
.shared-folder-name:hover {
    text-decoration: underline;
}
</style>